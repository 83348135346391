.services {

    /* center the section */
    /* margin: 75px auto auto auto; */

    text-align: center;
    margin: auto;
    background-color: var(--silver);
    padding: 50px;

}

.services__content {
    margin: auto;
}

.button--contact {
    /* background: linear-gradient(90deg, #d359ff7f, #fff70080); */
}

.card {
    margin: auto;
    /* center the section, add top and bottom margins */
    /* margin: 75px auto 75px auto; */

    /* ensure card is a square when resized */
    /* this also automatically sizes the box */
    aspect-ratio: 1 / 1;
    
    border-radius: 10px;
    background-color: cyan;
    display: grid;

}

.card__footer, .card__image {
    grid-area: 1 / 1;

}

.card__footer {
    /* percentage of parent component */
    height: 20%;
    width: 100%;

    border-radius: 0px 0px 10px 10px;
    background-color: blue;
    align-self: end;

}

.card__image {
    /* percentage of parent component */
    height: 80%;
    width: 80%;

    /* vertically center */
    align-self: center;
    /* horizontally center */
    margin: auto;
    border-radius: 10px;
    /* background-color: purple; */
    box-shadow: 0 0 25px 7px #000000;

}

.grid {
    /* margin: auto; */
    /* width: 150%; */
    /* height: 70vw; */
    /* center the section, add top and bottom margins */
    /* margin: 75px auto 75px auto; */
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);


}

.services__text {
    color: var(--black);
}

/* 800 is arbitrary */
@media screen and (min-width: 800px) {
    .services__content {
       max-width: 75%;
    }
}

/* 800 is arbitrary */
@media screen and (max-width: 500px) {
    .grid {
        /* center the section, add top and bottom margins */
        /* margin: 75px auto 75px auto; */
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(1, 1fr);
    }

    .card {
        width: 70vw;
        height: 70vw;
    }


}
