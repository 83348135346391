.contact-view {
    background-color: var(--background_clr);
    text-align: center;
    margin: auto;
    background-color: var(--silver);
    padding: 50px;
    
}

.contact__content {
    margin: auto;
}

.contact__details {
    /* display: flex; */
    /* flex-direction: column; */
    /* background: linear-gradient(90deg, #ABD9D9, #6D8DDA); */
    background: var(--silver);
    /* padding: 10px; */
    /* width: 100%; */
    margin: auto;
    border-radius: 10px;
    border-color: var(--orange);
    border-width: 1vw;
}

.logo {
    /* margin: 0; */
    /* margin-left: 10px; */
    /* padding: 0.5rem; */
    /* margin: auto; */
    /* align-items: center; */
    /* align-content: center; */

}

.contact_methods {
    /* the anchor button size is based on text, need to add margin to stop it overlapping with text above */
    /* margin: 100px; */
    display: inline-block;
    /* align-items: center; */
    /* justify-content: center; */
    margin-top: 5vw;
}

.contact__details--item {
    /* margin: 2.5vw; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
}

.contact__details--text {
    color: var(--black);
    margin: 0;
}

.qr_code {
    width: 50vw;
    height: 50vw;
    background-color: white;
    /* margin: 100px auto; */
    border-radius: 10px;
    margin: 5vw;
    /* padding: 10px; */
}

.button--form {
    /* background: linear-gradient(90deg, #d359ff7f, #fff70080); */
}

.contact__text {
    color: var(--black);
}


/* 800 is arbitrary */
@media screen and (min-width: 800px) {
    .contact__content {
        max-width: 75%;
    }

    .qr_code {
        width: 20vw;
        height: 20vw;

    }

    .contact__details {
        max-width: 50%;
    }

}
