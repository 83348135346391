.text__heading {
    text-align: center;
    color: var(--orange);
    /* font-size: 1.5rem; */
    font-size: 15vw;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.text__subheading {
    text-align: center;
    color: var(--silver);
    font-size: 7vw;
    font-weight: 500;
}

/* .text__highlighted {
    background: linear-gradient(90deg, #d359ff7f, #fff70080);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -200%;
    animation: animatedText 1s infinite alternate-reverse;
} */

@keyframes animatedText {
    from {
        background-position: 0%;
    }
    to {
        background-position: 100%;
    }
}


/* .button__highlighted {
    animation: animatedButton 1s infinite alternate-reverse;
} */

.button__highlighted:hover {
    box-shadow: 0 0 50px 2px #fff70080;
}

@keyframes animatedButton {
    from {
        box-shadow: 0 0 50px 1px #fff70080;
    }
    to {
        box-shadow: 0 0 0px 0px #fff70080;
    }
}

.anchor_button_styling {
    text-decoration: none;
    padding: 2.5vw;
    border-style: solid;
    border-radius: 10px;
    border-color: var(--white);
    border-width: 0.3vw;
}

.button:hover {
    /* box-shadow: 0 0 50px 2px #fff70080; */
}

.button {
    width: 40vw;
    height: 10vw;
    /* margin: 1vw; */
    font-size: 4vw;
    font-weight: 500;
    background: var(--orange);
    color: var(--black);
    /* border-color: var(--font-colour); */
    border-style: solid;
    border-radius: 10px;
    border-width: 0.3vw;
    transition: all 0.25s linear;
    cursor:pointer;
}

/* 800 is arbitrary */
@media screen and (min-width: 800px) {
    .text__heading {
        font-size: 8vw;
    }
    
    .text__subheading {
        font-size: 3vw;
    }

    .button {
        width: 20vw;
        height: 5vw;
        font-size: 2vw;

    }

    .anchor_button_styling {
        padding: 1.5vw;
    }

}

