.hero {
    text-align: center;
    background-image: url(../../assets/img/construction_reflection.jpg);
    /* darken image */
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(../../assets/img/construction_reflection.jpg);
    background-repeat:no-repeat;
    background-position:center;
    /* background-size: contain; */
    background-size: cover;
    /* background-size: 100% 100%; */
    /* background-position: center center; */

    /* ----- make margin on top for header ----- */
    /* margin-top: 100px; */
    margin: 100px auto auto auto;
    padding-bottom: 10px;

    
}

.hero__content {
    margin: auto;
}

.hero__text {
    margin: 0;
    letter-spacing: 0.5px;
    color: var(--orange);

}

.hero__logo {
    margin: 0;
    /* color: var(--orange); */
}

.hero__slogan {
    margin: 0;
    font-weight: 500;

}

.hero__button {
    /* min-width: 100px; */
    width: 40vw;
    height: 10vw;
    margin: 1vw;
    /* padding: 40px; */
    /* font-size: 0.5rem; */
    font-size: 4vw;
    font-weight: 500;
    /* border: none; */
    background: transparent;
    color: var(--font-colour);
    border-color: var(--font-colour);
    border-style: solid;
    border-radius: 10px;
    border-width: 0.3vw;
    /* box-shadow: 0 0 30px 2px #fff; */


    transition: all 0.25s linear;

    cursor:pointer;

}

.hero__button:hover {
    /* background: #3b3b3b; */
    background: var(--orange);
}

/* 800 is arbitrary */
@media screen and (min-width: 800px) {
    .hero__content {
        max-width: 75%;
    }

    .hero__logo {
        font-size: 10vw;
    }

    .hero__text {
        font-size: 3vw;
    }

    .hero__button {
        width: 20vw;
        height: 5vw;
        font-size: 2vw;
    }


}


