:root {
    --background-colour: #101010;
    --font-colour: #ffffff;

    --hover-highlight: #919191;
    --red: #CD0000;
    --black: #000000;
    --grey: #6c6c6c;
    --dark-grey: #3a3a3a;
    --silver: #999999;
    --white: #ffffff;
    --green: #69b139;
    --orange: #ffc549;
}