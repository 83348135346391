.about {
    /* limit the website to only use the middle section of the screen */
    /* max-width: 250px; */
    /* max-width: 75%; */

    /* center the section */
    /* margin: 75px auto auto auto; */
    
    text-align: center;
    margin: auto;
    background-color: var(--dark-grey);
    padding: 50px;

}

.about__content {
    margin: auto;
}

.button:hover {
    background: var(--orange);
}

.button--reviews {
    /* background: linear-gradient(90deg, #d359ff7f, #fff70080); */
}

.button--sustainability {
    background: linear-gradient(90deg, #6dffa082, #76947c79);
}


.tree_art_container {
    margin: auto;
    max-width: 75%;

    /* display: inline; */

    /* width: 40vw;
    height: 1vw; */
    /* margin-left: 150px; */
    /* margin: auto; */
}

/* 800 is arbitrary */
@media screen and (min-width: 800px) {
 .about__content {
    max-width: 75%;
 }
}



