.reviews {
    text-align: center;
    margin: auto;
    background-color: var(--dark-grey);
    padding: 50px;
    
}

.reviews__content {
    margin: auto;
    padding-bottom: 0.5vw;
}

.reviews__card {

    margin: 10vw;

    background-color: #1E2938;
    border-radius: 1vw;
    display: block;
    outline: 1.5vw solid rgb(208, 208, 208);

}

.reviews__card__header {
    /* percentage of parent component */
    /* height: 90%; */
    /* width: 100%; */
    background-color: #c9c9c9;
    border-radius: 1vw;
    box-shadow: 0 0 5vw 1vw #000000;
    outline: 2vw solid white;
    padding: 5vw;

}

.reviews__card__header__text {
    /* text-align: center; */
    color: #525252;
    /* font-size: 0.7rem; */
    font-size: 5vw;
    font-weight: 400;   
    margin: 0 1vw 0 1vw;

}


.reviews__card__footer {
    /* footer height = (100% - height of card header) */
    /* percentage of parent component */
    /* height: 90%; */
    /* width: 100%; */

    /* margin: auto; */

    display: flex;
    /* add horizontal space between the client name and address */
    /* gap: 10px; */
    /* align-items: center; */
    /* text-align: center; */
    /* align-content: center; */
    padding: 4vw;
    /* margin: auto; */
    

}

.reviews__card__clientname {
    /* padding: 1vw; */
    margin: auto;
    /* font-size: 0.5rem; */
    font-size: 5vw;
    font-weight: 400;
    color: white;
}

.reviews__card__clientaddress {
    /* padding: 1vw; */
    margin: auto;
    /* font-size: 0.5rem; */
    font-size: 5vw;
    font-weight: 400;
    color: #76947C;

}

/* 800 is arbitrary */
@media screen and (min-width: 800px) {
    .reviews__content {
       max-width: 75%;
    }

    .reviews__card {
        margin: 5vw;
        border-radius: 0.5vw;
        outline: 0.5vw solid rgb(208, 208, 208);
    }

    .reviews__card__header {
        border-radius: 0.5vw;
        box-shadow: 0 0 5vw 1vw #000000;
        outline: 1vw solid white;
        padding: 2vw;
    }

    .reviews__card__header__text {
        font-size: 2vw;
    
    }

    .reviews__card__footer {
        padding: 1.5vw;
    }

    .reviews__card__clientname {
        font-size: 2vw;
    }
    
    .reviews__card__clientaddress {
        font-size: 2vw;
    
    }

}
