.contact-form {
    /* center div in the center of the page */
    /* display: flex; */
    /* justify-content: center; */
    /* background-color: var(--white); */
    /* padding: 0 10rem 0 10rem; */
    
    
}

.contact-form form {
    background: var(--white);
    /* position: absolute; */
    border-radius: 5px;

}

.contact-form-contents-div {
    padding: 1rem;
    transition: 0.15s;

}

.contact-form-contents-div:hover {
    transform: scale(1.02);

}

.contact-form-input-div {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;

}


.contact-form input {
    background: var(--font-colour);
    padding: 0.50rem;
    border-style: solid;
    border-radius: 7px;
    border-width: 0.075rem;
    outline: none; /* remove outline when focused on element. accessibility maintained by using :focus styling */
    font-family: 'Times New Roman', Times, serif;
    transition: 0.25s;

}

.contact-form input:hover, .contact-form input:focus {
    background: var(--hover-highlight);

}

.contact-form textarea {
    background: var(--font-colour);
    padding: 0.50rem;
    border-style: solid;
    border-radius: 7px;
    border-width: 0.075rem;
    outline: none; /* remove outline when focused on element. accessibility maintained by using :focus styling */
    font-family: 'Times New Roman', Times, serif;
    transition: 0.25s;

}

.contact-form textarea:hover, .contact-form textarea:focus {
    background: var(--hover-highlight);

}

/* VALID INPUT FIELD */
.contact-form-input-valid {
    color: var(--grey);
    border-color: var(--black);

}

/* INVALID INPUT FIELD */
.contact-form-input-invalid {
    color: var(--red);
    border-color: var(--red);

}


.contact-form-error {
    padding: 0.25rem;
    font-size: 0.50rem;
    text-align: center;
    color: var(--red);
    font-family: 'Times New Roman', Times, serif;

}

.contact-form-submit-button {
    padding: 0.50rem;
    background: linear-gradient(90deg, #6dffa082, #76947c79);
    color: var(--white);
    font-family: 'Times New Roman', Times, serif;
    border: none;
    border-width: 0.075rem;
    border-radius: 7px;

}


