.header-container {
    background-color: var(--white);

    /* display: grid; */
    /* place logo and nav buttons on single row */
    /* grid-template-areas: "logo nav__links"; */

    display: flex;

    /* align-items: center; */
    /* padding: 100px 0 100px 0; */
    margin-bottom: 100px; /* margin same as the margin on hero view */
    justify-content: center;

    /* ----- keep header on top when scrolling ----- */
    /* position: sticky; */
    /* top: 0; */
    /* z-index: 999; */
    position: fixed; /* Make it stick/fixed */
    top: 0; /* Stay on top */
    width: 100%; /* Full width */
    transition: top 0.3s; /* Transition effect when sliding down (and up) */
    overflow-anchor: none;

}
.header-container.is-hidden {
    /* display: none; */
    top: -100%;
}

.header__row1 {
    display: flex;
    align-items: center;
}

.logo {
    margin: 0;
    /* margin-left: 10px; */
    padding: 0.5rem;
    color: var(--black);
    font-weight: 1000;
    /* font-size: 1.25rem; */
    font-size: 3vw;
}

.nav {
    /* justify-content: center; */
}

.nav__links {
    /* organise buttons inline on a single row */
    /* display: flex; */
    /* push buttons towards the right of the flex container */
    /* justify-content: space-around; */
    /* display: flex; */
    /* flex-direction: column; */
   

}

.nav__links button {
    /* font-size: 0.5rem; */
    font-size: 1.5vw;
    font-weight: 800;
    border: none;
    background-color: transparent;
    color: var(--black);
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.25s linear;
    border-radius: 10vw;
    padding: 1vw 2vw 1vw 2vw;
}

.nav__links button:hover {
    /* text-decoration-color: var(--black); */
    background-color: var(--orange);

}

.dvs-header__trigger {
    display: none;
}

/* .burger-menu-btn {
    display: none;
} */





/* if the width of the browser is less than or equal to 155px */
@media screen and (max-width: 800px) {
    /* place the logo, and nav buttons, on separate rows */
    .header-container {
        display: grid;
        /* grid-template-areas: "logo" "nav"; */
        grid-template-areas: "header__row1" "nav";
        /* min-width: 100%; */

    }

    .logo {
        /* horizontally center logo */
        margin: auto;
        font-size: 40px;
    }

    .nav__links {
        
        /* place buttons on top of each other */
        display: none;

        
    }
    
    .nav__links button {
        font-size: 30px;
        padding: 0 3vw 0 3vw;
    }

    .nav__links.is-open {
        text-align: center;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .dvs-header__trigger {
        display: block;
    }

    .nav {
        justify-content: center;
        width: 100%;
    }

    .burger-menu-btn {
        width: 50px;
        height: 50px;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
    
    .burger-menu-btn.is-open {
        /* width: 50px;
        height: 50px; */
        /* background-color: yellow; */
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: center; */
        /* align-items: center; */
    }

    .burger-menu-btn__bar {
        margin: 5px;
        width: 100%;
        height: 100%;
        background-color: grey;
        border-radius: 0.5em;
    }


}
