.background_glow_container__purple {
    /* width: 50vw;
    height: 50vw; */
    width: 50px;
    height: 50px;

    position: fixed;
    top: 0px;
    left: 20px;

    background: #df53ff;
    border-radius: 100%;

    position: absolute;
    z-index: -1;

    box-shadow: 0 0 1000px 1000px #dd45ffb5;

    filter: blur(50px);

}

.background_glow_container__blue {
    /* width: 50vw;
    height: 50vw; */
    width: 50px;
    height: 50px;

    position: fixed;
    top: 0px;
    right: 20px;

    background: #4e97ea;
    border-radius: 100%;

    position: absolute;
    z-index: -1;

    box-shadow: 0 0 1000px 1000px #4e97eacc;

    filter: blur(50px);
}

