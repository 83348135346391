.footer-view {
    /* background: linear-gradient(90deg, #ABD9D9, #6D8DDA); */
    background: var(--orange);
    /* padding: 1px; */
    /* margin: 1rem 0 1rem 0; */
    /* border-radius: 5px; */
    /* align-items: center; */
    /* align-content: center; */
    /* justify-content: center; */
    /* margin: auto; */
    text-align: center;
    /* padding-bottom: 10px; */
    /* margin: 0; */


}

.footer__text {
    margin: 0;
    padding: 0;
}

.logo {
    /* margin: 0; */
    /* margin-left: 10px; */
    /* padding: 0.5rem; */
    /* margin: auto; */
    /* align-items: center; */
    /* align-content: center; */

}

.footer_contact_details {
    /* center the section, add top and bottom margins */
    /* margin: 75px auto 75px auto; */
    display: grid;
    /* gap: 10px; */
    grid-template-columns: repeat(3, 1fr);
    align-items: center; /* vertically center items */
    text-align: center; /* horizontally center items */

}

.footer_contact_details__item {
    display: flex;
    flex-direction: column; /* place icons above text */
    align-items: center;
    
}


/* 800 is arbitrary */
@media screen and (min-width: 800px) {


}


