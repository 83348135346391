body {
  background-color: var(--background-colour);
  font-family: 'outfit';
  
  /* remove margins which push content inwards */
  margin: 0;
  
  
}

h1 {
  color: var(--font-colour);
  font-weight: 1000;
  /* font-size: 3rem; */
  font-size: 20vw;
}

h2 {
  color: var(--font-colour);
  
  font-weight: 500;
  /* font-size: 1rem; */
  font-size: 75px;


}

h3 {
  color: var(--font-colour);
  font-weight: 1000;
  /* font-size: 1.25rem; */
  font-size: 75px;

}

h4 {
  color: var(--font-colour);
  /* font-size: xx-large; */
  font-size: 75px;


}

h5 {
  color: var(--font-colour);
  font-weight: 500;
  font-size: 5vw;

}

h7 {
  color: var(--font-colour);
  /* font-size: 0.75rem; */
  font-size: 5vw;
  font-weight: 250;

}

/* 800 is arbitrary */
@media screen and (min-width: 800px) {
  h7 {
    font-size: 2vw;
  }

  h5 {
    font-size: 2vw;
  }

}



