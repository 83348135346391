.App {

  /* limit the website to only use the middle section of the screen */
  /* max-width: 400px; */
  /* max-width: 50%; */
  /* max-width: 75%; */

  /* center the section */
  margin: auto;


}